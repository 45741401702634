<div class="container pd-50" *ngIf="isBrowser">
    <!-- cab-service-card.component.html -->
    <div class="card">
        <img src="../../assets/img/slider/slider-1.jpg" *ngIf="!isDistrictOnly" alt="Cab Service" class="card-image" loading="lazy">
        <div class="card-content row">          
          <div class="services-offered" style="color: white;">
            <section *ngIf="!isDistrictOnly">
                <h2 style="color: white;">Drop Taxi Service</h2>
                <p style="color: white;">Experience the convenience of our Drop Taxi service from all districts. Whether it's a business trip or a leisurely journey, One Drop Cab ensures a comfortable and reliable transportation experience.</p>
                <p style="color: white;">Why choose our One Drop Cab for Drop Taxi service?</p>
                <ul>
                    <li>🚗 Comfortable and well-maintained vehicles</li>
                    <li>⏰ Punctual and professional, highly experienced drivers </li>
                    <li>💰 Affordable rates with transparent pricing</li>
                    <li>🌐 Wide coverage across all districts</li>
                </ul>
                <p style="color: white;">Book your Drop Taxi Service with One Drop Cab now and enjoy a stress-free journey.</p>               
            </section>
            <section >
                <h2 style="color: white;">Drop Taxi Service by One Drop Cab Available in Below Districts.</h2>
                <div class="row">                    
                    <ul *ngFor="let route of cities" class="col-md-4 footer-list">
                        <li><h2><a href="district/{{route?.citymaster.toLowerCase()}}"><i class="fas fa-caret-right"></i>Drop Taxi in {{route?.citymaster}}</a></h2></li>
                       <!-- <li><a href="one-way-drop-taxi/{{route?.citymaster.toLowerCase()}}"><i class="fas fa-caret-right"></i> {{route?.citymaster}}</a></li> -->
                    </ul>
                </div>
                <a *ngIf="!isDistrictOnly" href="service" style="    display: flex;
                margin: auto;
                width: fit-content;" class="theme-btn mt-4">Discover More About Our Popular Routes<i
                    class="fas fa-arrow-right"></i></a>
                    <a *ngIf="!isDistrictOnly" href="one-way-drop-taxi-innova" style="    display: flex;
                    margin: auto;
                    width: fit-content;" class="theme-btn mt-4">Our Premium Innova One Way Drop Service<i
                        class="fas fa-arrow-right"></i></a>
            </section>
        </div>
      </div>
      
    </div>>