import { AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedModule } from '../shared/Shared.Module';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AppConstants } from '../shared/app.constants';
declare var $: any;

@Component({
  selector: 'app-all-district',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './all-district.component.html',
  styleUrl: './all-district.component.scss',
})
export class AllDistrictComponent implements AfterViewInit, OnInit{
  SEOKey="One Drop Cab Service, One-Way Taxi,  All Districts Cab,Reliable One-Way Drop Service, Affordable Cab Service, Punctual One-Way Transportation, Convenient Drop Service, Professional Cab Drivers, Stress-Free Travel, OneDropCabService";
  SEODescription= "Embark on extraordinary journeys with One Drop Cab, the avant-garde cab service redefining travel in South India. Book now for seamless rides, unmatched comfort, and a dash of urban elegance.";
  SEOTitle="The Benefits of Choosing a Drop Taxi Service for Your Next Trip | One Drop Cab";
  selectedInfo: any;
  cities: any=[];
  isBrowser:boolean = false;
  @Input() isDistrictOnly: boolean=false;

  constructor(@Inject(PLATFORM_ID) private platformId: string,private route:ActivatedRoute, private router: Router,private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private doc){
    this.createLinkAndMetas(this.SEOTitle, this.SEOKey, this.SEODescription, true, false);
    if(isPlatformBrowser(this.platformId))
    {
      this.isBrowser = true;
      
    }
  }
  ngAfterViewInit(): void {
  }
  ngOnInit(): void {
    this.getCity();
  }
  getCity(){
    let jsonPayload1={"action":"fetchdistrict"
  }
 
  fetch(AppConstants.APP_URL+"?userid=123",{method:'POST',
  body:JSON.stringify(jsonPayload1)}).then((response) => response.json())
  .then((resData) =>{ 
    this.cities=resData;
    this.hideLoader();
  });
    }
  
  createLinkAndMetas(title: string, keywords: string, description: string, isCanonicalRequired: boolean, isLocation: boolean) {

    this.titleService.setTitle(title);
    var keys = "";
    var prekeys=keywords.toString().split(",");
    if(isLocation)
    {
      var loc = this.doc.URL.split("/")[4];
      for(var i=0;i< prekeys.length; i++)
        {      
          keys+=prekeys[i]+", "
        }
        keys = keys.substring(0,keys.length-1);
    }
    else{
      keys  = keywords
    }
    
    keys = keys.toLocaleLowerCase();
    this.metaService.updateTag(              
    {
      name: 'description', content: description
    })
    this.metaService.updateTag(              
    {
      name: 'keywords', content: keys
    })
    
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'og:image:alt', content:title });
    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'twitter:url', content:window.location.origin});
    this.metaService.updateTag({ property: 'twitter:site', content:'One Drop Cab'});
    if(isCanonicalRequired &&isPlatformBrowser(this.platformId))
    {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
 }
 hideLoader(){
  if(this.isBrowser)
  { 
    setTimeout(() => {
      $('.preloader').fadeOut();
    }, 50);
  }
}
}
